<script>
import { mapGetters } from "vuex";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";

// import Revenue from "./revenue";
// import SalesLocation from "./sales-location";

export default {
  components: {
    Layout,
    // Swiper,
    // SwiperSlide,
    PageHeader,
  },
  data() {
    return {
      title: "Historique des recharges",
      items: [
        {
          text: "SMS Dashboard",
          href: "/",
        },
        {
          text: "GICAM SMS Dashboard",
          href: "/campaign/sms",
        },
        {
          text: "Historique des recharges",
          active: true,
        },
      ],
    };
  },
  methods: {
    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n
        .split(".")[0]
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  computed: {
    ...mapGetters("campaigns", ["campaigns"]),
    ...mapGetters("system", ["partner", "isAdmin"]),
    recharges() {
      return this.partner.recharges || [];
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="$router.replace('/campaign/sms')"
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>
    </div>

    <div class="col-xxl-12 mt-4">
      <div class="card" id="contactList">
        <!----><!---->
        <div class="card-header">
          <div class="row align-items-center g-3">
            <div class="col-md-3">
              <h5 class="card-title mb-0">Transactions</h5>
            </div>
            <div class="col-md-auto ms-auto">
              <div class="d-flex gap-2">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control search"
                    placeholder="Recherche..."
                  /><i class="ri-search-line search-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!----><!---->
          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th
                    class="sort"
                    data-sort="name"
                    scope="col"
                    style="width: 60px"
                  ></th>
                  <th class="sort" data-sort="date" scope="col">Date</th>
                  <th class="sort" data-sort="amount" scope="col">Montant</th>
                  <th class="sort" data-sort="qty" scope="col">Quantité</th>
                  <!-- <th class="sort" data-sort="status" scope="col">Status</th> -->
                </tr>
              </thead>
              <tbody class="list form-check-all">
                <tr v-for="recharge of recharges" :key="recharge.id">
                  <td>
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle fs-16 bg-soft-success text-success"
                      >
                        <i class="ri-arrow-right-up-fill"></i>
                      </div>
                    </div>
                  </td>
                  <td class="date">
                    {{ recharge.create_date }}
                  </td>
                  <td class="amount">
                    {{ formatNumber(recharge.amount) }}
                  </td>
                  <td class="qty">
                    {{ formatNumber(recharge.qty) }}
                  </td>
                  <!-- <td class="status">
                    <span class="badge fs-11 badge-soft-success">
                      <i class="ri-time-line align-bottom"></i> Failed
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!----><!---->
      </div>
    </div>
  </Layout>
</template>
